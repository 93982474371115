<template>
  <v-container fluid>
    <v-row>
      <v-col lg="10" cols="12" class="mx-auto my-8">
        <v-stepper
          alt-labels
          elevation="0"
          v-model="e1"
          class="bg-transparent overflow-visible"
        >
          <v-stepper-header class="shadow-0">
            <v-stepper-step step="1" color="#344767">
              <span class="text-secondary font-weight-normal">帳號</span>
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="2" color="#344767">
              <span class="text-secondary font-weight-normal">基本資訊</span>
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="3" color="#344767">
              <span class="text-secondary font-weight-normal">其他資訊</span>
            </v-stepper-step>

            <!--             <v-divider></v-divider>

            <v-stepper-step step="4" color="#344767">
              <span class="text-secondary font-weight-normal">Profile</span>
            </v-stepper-step> -->
          </v-stepper-header>

          <v-stepper-items
            class="border-radius-xl overflow-hidden shadow-lg mt-8"
          >
            <v-stepper-content step="1" class="border-radius-xl px-4 pt-4">
              <v-card>
                <div>
                  <v-row justify="center">
                    <v-col sm="6" cols="12">
                      <label class="text-xs text-typo font-weight-bolder ms-1"
                        >帳號</label
                      >
                      <v-text-field
                        autocomplete="nope"
                        v-model="username"
                        hide-details
                        outlined
                        dense
                        label="請輸入帳號"
                        prepend-icon="mdi-account-outline"
                        class="font-size-input border border-radius-md mt-2"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row justify="center">
                    <v-col sm="6" cols="12">
                      <label class="text-xs text-typo font-weight-bolder ms-1"
                        >Email</label
                      >
                      <v-text-field
                        v-model="email"
                        type="email"
                        hide-details
                        outlined
                        dense
                        prepend-icon="mdi-email-outline"
                        class="font-size-input border border-radius-md mt-2"
                        label="請輸入電子郵件"
                        autocomplete="username"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row justify="center">
                    <v-col sm="6" cols="12">
                      <label class="text-xs text-typo font-weight-bolder ms-1"
                        >輸入密碼</label
                      >
                      <v-text-field
                        v-model="password"
                        :type="showPassword ? 'text' : 'password'"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="showPassword = !showPassword"
                        hide-details
                        outlined
                        dense
                        label="請輸入密碼"
                        prepend-icon="mdi-lock-open-outline"
                        class="font-size-input border border-radius-md mt-2"
                        autocomplete="new-password"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row justify="center">
                    <v-col sm="6" cols="12">
                      <label class="text-xs text-typo font-weight-bolder ms-1"
                        >再輸入密碼</label
                      >
                      <v-text-field
                        v-model="password2"
                        :type="showPassword2 ? 'text' : 'password'"
                        :append-icon="showPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="showPassword2 = !showPassword2"
                        hide-details
                        outlined
                        dense
                        label="請輸入密碼"
                        prepend-icon="mdi-lock-open-outline"
                        class="font-size-input border border-radius-md mt-2"
                        autocomplete="new-password"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row justify="center">
                    <p class="text-body ma-2">
                      {{ $t("Already have an account?") }}
                      <a
                        href="/pages/authentication/signin"
                        class="
                          text-dark text-decoration-none
                          font-weight-bolder
                        "
                        >{{ $t("Sign In") }}</a
                      >
                    </p>
                  </v-row>
                </div>

                <div class="text-end mx-4">
                  <v-btn
                    :ripple="false"
                    :elevation="0"
                    class="
                      font-weight-bold
                      text-xs
                      btn-default
                      bg-gradient-default
                      py-5
                      px-6
                      mt-6
                      mb-2
                      me-2
                    "
                    color="primary"
                    @click="e1 = 2"
                  >
                    Next
                  </v-btn>
                </div>
              </v-card>
            </v-stepper-content>

            <v-stepper-content step="2" class="border-radius-xl px-4 pt-4">
              <v-card>
                <v-row class="mx-4">
                  <v-col cols="4">
                    <label class="text-xs text-typo font-weight-bolder ms-1">{{
                      $t("Last Name")
                    }}</label>
                    <v-text-field
                      v-model="last_name"
                      placeholder="錢"
                      hide-details
                      outlined
                      class="font-size-input border border-radius-md mt-2"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <label class="text-xs text-typo font-weight-bolder ms-1">{{
                      $t("First Name")
                    }}</label>
                    <v-text-field
                      v-model="first_name"
                      placeholder="多多"
                      hide-details
                      outlined
                      class="font-size-input border border-radius-md mt-2"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <label class="text-xs text-typo font-weight-bolder ms-1">{{
                      $t("Mobile")
                    }}</label>
                    <v-text-field
                      v-model="mobile_phone"
                      placeholder="0988123456"
                      hide-details
                      outlined
                      class="font-size-input border border-radius-md mt-2"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="6">
                    <label class="text-xs text-typo font-weight-bolder ms-1">{{
                      $t("Company")
                    }}</label>
                    <v-text-field
                      v-model="company"
                      hide-details
                      outlined
                      placeholder="旺得發公司"
                      class="font-size-input border border-radius-md mt-2"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <label class="text-xs text-typo font-weight-bolder ms-1">{{
                      $t("Department")
                    }}</label>
                    <v-text-field
                      v-model="department"
                      placeholder="業務"
                      hide-details
                      outlined
                      class="font-size-input border border-radius-md mt-2"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="4">
                    <label class="text-xs text-typo font-weight-bolder ms-1">{{
                      $t("Job Title")
                    }}</label>
                    <v-text-field
                      v-model="job_title"
                      hide-details
                      outlined
                      placeholder="業務主任"
                      class="font-size-input border border-radius-md mt-2"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <label class="text-xs text-typo font-weight-bolder ms-1">{{
                      $t("Office")
                    }}</label>
                    <v-text-field
                      v-model="office_phone"
                      hide-details
                      outlined
                      placeholder="02 26785413"
                      class="font-size-input border border-radius-md mt-2"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <label class="text-xs text-typo font-weight-bolder ms-1">{{
                      $t("Ext Number")
                    }}</label>
                    <v-text-field
                      v-model="ext_phone"
                      hide-details
                      outlined
                      placeholder="2023"
                      class="font-size-input border border-radius-md mt-2"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <!-- <div class="d-flex"> -->
                <v-row class="mx-4">
                  <v-col col="6">
                    <v-btn
                      :ripple="false"
                      :elevation="0"
                      class="
                        font-weight-bold
                        text-xs text-dark
                        btn-light
                        bg-gradient-light
                        py-5
                        px-6
                        mt-6
                        mb-2
                        ms-2
                      "
                      @click="e1 = 1"
                    >
                      Prev
                    </v-btn>
                  </v-col>
                  <v-col col="6" class="text-right">
                    <v-btn
                      :ripple="false"
                      :elevation="0"
                      class="
                        font-weight-bold
                        text-xs
                        btn-default
                        bg-gradient-default
                        py-5
                        px-6
                        mt-6
                        mb-2
                        me-2
                        ms-auto
                      "
                      color="primary"
                      @click="e1 = 3"
                    >
                      Next
                    </v-btn>
                  </v-col>
                  <!-- </div> -->
                </v-row>
              </v-card>
            </v-stepper-content>

            <v-stepper-content step="3" class="border-radius-xl px-4 pt-4">
              <v-card height="390px">
                <div>
                  <h5 class="font-weight-bolder text-h5 text-typo mb-7">
                    {{ $t("Socials") }}
                  </h5>
                  <v-row class="mx-4">
                    <v-col cols="12">
                      <label class="text-xs text-typo font-weight-bolder ms-1"
                        >Line ID</label
                      >
                      <v-text-field
                        v-model="line_id"
                        hide-details
                        outlined
                        placeholder="john"
                        class="font-size-input border border-radius-md mt-2"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="mx-4">
                    <v-col cols="12">
                      <label class="text-xs text-typo font-weight-bolder ms-1"
                        >Facebook</label
                      >
                      <v-text-field
                        v-model="fb_id"
                        hide-details
                        outlined
                        placeholder="john"
                        class="font-size-input border border-radius-md mt-2"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row class="mx-4">
                    <v-col cols="12">
                      <label class="text-xs text-typo font-weight-bolder ms-1"
                        >Telegram Account</label
                      >
                      <v-text-field
                        v-model="telegram_id"
                        hide-details
                        outlined
                        placeholder="https://..."
                        class="font-size-input border border-radius-md mt-2"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </div>
                <div class="d-flex">
                  <v-btn
                    :ripple="false"
                    :elevation="0"
                    class="
                      font-weight-bold
                      text-xs text-dark
                      btn-light
                      bg-gradient-light
                      py-5
                      px-6
                      mt-6
                      mb-0
                    "
                    @click="e1 = 2"
                  >
                    Prev
                  </v-btn>
                  <v-btn
                    :ripple="false"
                    :elevation="0"
                    class="
                      font-weight-bold
                      text-xs
                      btn-default
                      bg-gradient-default
                      py-5
                      px-6
                      mt-6
                      mb-0
                      ms-auto
                    "
                    color="primary"
                    @click="signup()"
                  >
                    註冊
                  </v-btn>
                </div>
              </v-card>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      e1: 1,
      info_dialog: false,
      info_title: "訊息",
      info_message: "",
      stepper_target: 1,
      loading: false,
      showPassword: false,
      showPassword2: false,
      username: null,
      password: null,
      password_hint: null,
      password2: null,
      email: null,
      email_hint: null,
      last_name: null,
      last_name_hint: null,
      first_name: null,
      first_name_hint: null,
      mobile_phone: null,
      company: null,
      department: null,
      job_title: null,
      office_phone: null,
      ext_phone: null,
      telegram_id: null,
      line_id: null,
      fb_id: null,
      buyerUBN: null,
      accountRules: [
        (v) => !!v || "帳號必填",
        (v) => (v && v.length >= 3) || "帳號至少3字元",
      ],
      emailRules: [
        (v) => !!v || "E-mail必填",
        (v) =>
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail 格式無效",
      ],
      passwordRules: [(v) => !!v || "密碼必填"],
      nameRules: [(v) => !!v || "名稱必填"],
    };
  },
  methods: {
    signup() {
      if (this.username.length > 0 && this.password.length > 0) {
        let url = process.env.VUE_APP_SERVER_URL + "/api/v1/users/signup";
        console.log(" process.env.VUE_APP_SERVER_URL" + url);
        let currentObj = this;
        let data = {
          email: this.email,
          username: this.username,
          password: this.password,
          first_name: this.first_name,
          last_name: this.last_name,
          company: this.company,
          department: this.department,
          job_title: this.job_title,
          office_phone: this.office_phone,
          ext_phone: this.ext_phone,
          mobile_phone: this.mobile_phone,
          line_id: this.line_id,
          fb_id: this.fb_id,
          telegram_id: this.telegram_id,
        };
        console.log("send ", data);

        let config = {
          headers: {
            "Content-Type": "application/json",
          },
        };

        this.axios
          .post(url, data, config)
          .then(function (response) {
            currentObj.setResult(response.data);
          })
          .catch(function (error) {
            console.log(error);
            console.log(error.response);
            if (
              error.response.data != null &&
              error.response.data.detail != null
            ) {
              if (
                error.response.data.detail.includes(
                  "The user with this username already exists in the system."
                )
              ) {
                alert("User exist");
              }
            }
          });
      } else {
        // alert("請輸入正確資料");
      }
    },
    setResult(data) {
      console.log(data);
      alert("註冊成功");
      this.$router.push({ name: "Verify", query: { email: this.email } });
    },
  },
};
</script>

<!--  read the following to send email by gmail api https://mailtrap.io/blog/python-send-email-gmail/ -->
